<template>
  <div>
    <div class="tmp"></div>
    <v-card>
      <v-card-title class="justify-center">
        저장 완료 문구
      </v-card-title>
    </v-card>
  </div>
</template>
 
<script>
export default {
  name: 'success',
}
</script>
<style scoped>
.tmp {
  height: 4.09rem;
}
</style>